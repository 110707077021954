var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-card"},[_c('div',{staticClass:"row ml-0 pr-4 pr-md-2 p-2 pt-md-3 d-block d-md-none animate__animated animate__slideInRight",staticStyle:{"max-height":"15rem","overflow-y":"auto"}},[_c('h4',{staticClass:"caption",staticStyle:{"border":"none !important"}},[_vm._v("طول جغرافیایی:")]),_c('h6',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.getFarmInfo.coordination .substring(2, _vm.getFarmInfo.coordination.length - 1) .split(" ")[1] .substring(1, 8))+" ")]),_c('div',{staticClass:"caption"},[_vm._v("عرض جغرافیایی:")]),_c('div',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.getFarmInfo.coordination .substring(1, _vm.getFarmInfo.coordination.length - 1) .split(" ")[2] .substring(0, 7))+" ")]),_c('div',{staticClass:"caption"},[_vm._v("عنوان زمین:")]),_c('div',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.getFarmInfo.title)+" ")]),_c('div',{staticClass:"caption"},[_vm._v("نوع محصول:")]),_c('div',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.getFarmInfo.productType)+" ")]),_c('div',{staticClass:"caption"},[_vm._v("نام محصول:")]),_c('div',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.getFarmInfo.productName)+" ")]),_c('div',{staticClass:"caption"},[_vm._v("نوع کشت:")]),_c('div',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.cultiavtionType[+_vm.getFarmInfo.cultivationsType - 1])+" ")]),_c('div',{staticClass:"caption"},[_vm._v("مساحت (هکتار):")]),_c('div',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.getFarmArea)+" ")]),_c('div',{staticClass:"caption"},[_vm._v("تاریخ کاشت:")]),_c('div',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.getFarmInfo.cultivationsDate)+" ")])]),_c('div',{staticClass:"row d-none d-md-block",staticStyle:{"overflow-y":"auto"}},[_c('h5',{staticClass:"px-3"},[_vm._v(" "+_vm._s(_vm.getFarmInfo.title)+" ")]),_c('hr',{staticClass:"my-2"}),_c('KeyValue',{attrs:{"json":{
        // "طول جغرافیایی": getFarmInfo.coordination
        //         .substring(2, getFarmInfo.coordination.length - 1)
        //         .split(" ")[1]
        //         .substring(1, 8),
        // "عرض جغرافیایی": getFarmInfo.coordination
        //   .substring(1, getFarmInfo.coordination.length - 1)
        //   .split(" ")[2]
        //   .substring(0, 7),
        "نام محصول": _vm.getFarmInfo.productName,
        "نوع کشت": _vm.cultiavtionType[+_vm.getFarmInfo.cultivationsType - 1],
        "مساحت (هکتار)": _vm.getFarmArea,
        "تاریخ کاشت": _vm.toFarsiNumber(_vm.getFarmInfo.cultivationsDate),
      }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }