function removeZero(str) {
	if (str.startsWith("0")) {
		str = str.substring(1);
	}
	return str;
}

export const removeZeroFromDateService = {
	removeZero,
};
