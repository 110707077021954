<script>

export default {
  components: {},
  props: {
    json: {
      type: Object,
    },
  },
  data() {
    return {

    };
  },
  computed: {

  },
};

</script>

<template>
  <CRow class="gap-10">
    <CCol md="12" class="wrapper" v-for="item in Object.entries(json)" :key="item[0]">
      <span class="key">
        {{ item[0] }}:
      </span>
      <span class="value">
          {{item[1]}}
        </span>
    </CCol>
  </CRow>
</template>

<style scoped>
.wrapper{
  font-size: 13px;
}
</style>
