function calculateNumOfMonth(miladi) {
	let numOfMonthes = null;

	let dateFormatEn = new Intl.DateTimeFormat();
	const nowDate = dateFormatEn.format(Date.now());
	const splitedNow = nowDate.split("/");

	const monthNow = +splitedNow[0];
	const yearNow = +splitedNow[2];

	const monthDiffYears = yearNow - miladi.gy;

	if (monthDiffYears <= 0) {
		monthNow - miladi.gm >= 0
			? (numOfMonthes = monthNow - miladi.gm + 1)
			: 0;
		console.log(numOfMonthes);
	} else if (monthDiffYears >= 2) {
		numOfMonthes = 12;
	} else {
		numOfMonthes = 12 - miladi.gm + monthNow + 1;
		numOfMonthes >= 12 ? (numOfMonthes = 12) : numOfMonthes;
	}

	return numOfMonthes;
}

export const numOfAvaliableMonthService = {
	calculateNumOfMonth,
};
