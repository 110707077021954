import * as jalaali from "jalaali-js";
import { removeZeroFromDateService } from "../services/removeZeroFromDate.service";

function persianToMiladi(date) {
	const splitedFaDate = date.split(" ")[0].split("-");
	const year = splitedFaDate[0];
	let month = splitedFaDate[1];
	let day = splitedFaDate[2];

	removeZeroFromDateService.removeZero(month);
	removeZeroFromDateService.removeZero(day);

	const miladi = jalaali.toGregorian(+year, +month, +day);

	return miladi;
}
function miladiToPersian(date) { 
	const splitedFaDate = date.split(" ")[0].split("-");
	const year = splitedFaDate[0];
	let month = splitedFaDate[1];
	let day = splitedFaDate[2];

	removeZeroFromDateService.removeZero(month);
	removeZeroFromDateService.removeZero(day);

	const result = jalaali.toJalaali(year, month, day);

	return result;
}

export const persianToMiladiService = {
	persianToMiladi,
	miladiToPersian
};
