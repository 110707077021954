<template>
  <div class="p-0 m-0">
    <div class="menuBtnMd">
      <button
        class="chip d-none y-center-g-5 justify-content-around maptooltip animate__animated animate__slideInRight"
        :class="{ activeBtn: !!getIsFarmSelected }"
        :disabled="!getIsFarmSelected"
        @click="
          $router.push({
            name: 'هواشناسی',
            params: {
              coordinate: coordinate,
              farmName: isFarmSelected.title,
            },
          })
        "
      >
        <CIcon name="cil-cloudy" />
        <span class="">هواشناسی</span>
      </button>

      <button
        class="chip d-none y-center-g-5 justify-content-around p-mx-1 maptooltip animate__animated animate__slideInRight"
        @click="toggleCmp('indexes')"
        :class="{ activeBtn: !!getIsFarmSelected }"
      >
        <CIcon name="cibWindows" />
        <span class="">انتخاب شاخص</span>
      </button>

      <button
        class="chip d-none y-center-g-5 justify-content-around maptooltip animate__animated animate__slideInRight"
        :class="{ activeBtn: !!getIsFarmSelected }"
        @click="adviceORditeplanLoad('dietPlan')"
      >
        <CIcon name="cil-fastfood" />
        <span class="">برنامه غذایی</span>
      </button>

      <button
        class="chip d-none y-center-g-5 justify-content-around maptooltip animate__animated animate__slideInRight"
        :class="{ activeBtn: !!getIsFarmSelected }"
        @click="adviceORditeplanLoad('advice')"
      >
        <CIcon name="cil-education" />
        <span class="">توصیه ها</span>
      </button>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    isFarmSelected: {
      type: Object,
      default: null,
    },
    coordinate: {
      type: Array,
    },
  },
  computed: {
    getIsFarmSelected() {
      return this.isFarmSelected;
    },
  },
  methods: {
    toggleCmp(cmp) {
      if (this.getIsFarmSelected) {
        this.$emit("changeComponent", cmp);
        this.$emit("getAvaliableMonth");
      }
    },
    adviceORditeplanLoad(cmp) {
      if (this.getIsFarmSelected) {
        this.$emit("loadAdviceDietPlan", cmp);
      }
    },
  },
  mounted() {
  },
};
</script>

<style scoped>
.chip{
  background: #ddd;
}

#mobileMenuBg {
  height: 10rem;
  width: 100%;
  position: absolute;
  left: -15%;
  bottom: -6.7rem;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 15px;
  text-align: center;
  border-radius: 50% 50% 50% 50%;
  overflow: hidden;
}
#mobileMenu {
  height: 2rem;
  width: 100%;
  position: absolute;
  bottom: 4rem;
  background-color: transparent;
  padding: 15px;
  text-align: center;
  padding-bottom: 0;
}

.calendeBtn:hover {
  background: #f3f3f3;
}
.menuBtnMd {
  position: absolute;
  right: 20px;
  bottom: 20px;
  gap: 10px;
  display: flex;
  flex-direction: column;
}
.activeBtn {
  transform: translateX(5px);
  background: #fff;
  display: flex !important;
}
.activeBtn:hover {
  color: var(--primary);
}
</style>
