<template>
  <div>
    <div v-if="indicatorList.length == 0">
      <h6 class="text-white">
        {{ chooseIndexMessage }}
      </h6>
    </div>
    <div
      v-else class="chose-index-wrapper">
      <a
        v-for="(item, index) in indicatorList"
        :key="index"
        class="menuBtn cursor-pointer"
        @click="
        toggleCmp();
        loadIndicatorForm(item.id);
      "
      >
        <img
          :src="getSrc(item.icon)"
          :alt="item.title"
          style="width: 5rem; height: 5rem"
          class="p-mt-3"
        />
        <p class="m-0">{{ item.title }}</p>
        <h6 style="font-size: 10px; color: #959595">{{ item.description }}</h6>
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { apiUrlRoot } from "../../../constants/config";
export default {
  props: {
    farmSelectedId: {
      type: Number,
    },
  },
  data() {
    return {
      indicatorList: [],
      chooseIndexMessage: "در حال دریافت اطلاعات شاخص ها...",
    };
  },
  methods: {
    ...mapActions("indicatorClient", ["GetAllWithProduct"]),
    toggleCmp() {
      this.$emit("changeComponent", null);
    },
    loadIndicatorForm(layerId) {
      this.$emit("loadIndicatorForm", layerId);
    },
    async getAllIndicator() {
      let data = {
        FarmId: this.farmSelectedId,
      };
      let result = await this.GetAllWithProduct(data);
      if (result.length > 0) {
        this.indicatorList = result;
      } else {
        this.chooseIndexMessage = "برای زمین انتخاب شده هیچ شاخصی نیست";
      }
    },
    getSrc(src) {
      return `${apiUrlRoot}/indicator/${src}`;
    },
  },
  mounted() {
    this.getAllIndicator();
  },
};
</script>

<style scoped>
.chose-index-wrapper{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 7px;
  align-content: space-around;
  justify-content: space-between;
  align-items: center;
  justify-items: stretch;
}

.chose-index-wrapper .menuBtn{
  display: flex;
  background: #fff;
  border-radius: var(--border-radius);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

</style>
