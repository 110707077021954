<template>
  <div class="row justify-content-center text-center py-3 gap-10 indexInfo">
    <vue-element-loading
      :active="formLoading"
      text="درحال دریافت اطلاعات..."
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <CCol md="12">
      <v-select
        dir="rtl"
        v-model="selectedMonth"
        :options="monthAvaliableList"
        :placeholder="monthAvaliableDefault"
      ></v-select>
    </CCol>

    <hr />
    <div class="col-12 y-center-g-5" v-if="dayAvaliableList.length > 0">
      <CIcon name="cilCalendar" />
      <span>روز های موجود</span>
    </div>
    <div v-if="dayAvaliableList.length > 0" class="row p-2 m-0 dayAvaliable">
      <CRow class="" v-for="(date, index) in dayAvaliableList" :key="index">
        <CCol col="auto" class="px-1">
          <CButton
            color="parimary"
            :class="{ selectedBtnStyle: daySelected == date }"
            @click="setDaySelected(date)"
          >
            {{ date }}
          </CButton>
        </CCol>
      </CRow>
    </div>
    <div class="col-12" v-else>
      <CAlert color="danger">
        {{ dayResultError }}
      </CAlert>
    </div>
    <div class="col-12 y-center-g-5">
      <CIcon name="cilCheck" />
      <span>انتخاب شاخص</span>
    </div>
    <div class="col-12">
      <CRow class="d-none d-md-block justify-content-center mx-0">
        <CCol col="auto" v-for="(item, index) in indicatorList" :key="index">
          <CButton
            color="secondary"
            block
            :variant="layerId === item.id ? 'filled' : 'outline'"
            class="p-1 mb-1 text-right"
            @click="setIndicatorSelected(item.id)"
          >
            <img
              :src="getSrc(item.icon, 'Indicator')"
              :alt="item.title"
              style="width: 2rem; height: 2rem"
              class="m-1"
            />
            <span>{{ item.title }}</span>
          </CButton>
        </CCol>
      </CRow>
    </div>
    <div class="d-none d-md-block col-5 align-self-end mt-1">
      <CButton
        color="danger"
        variant="outline"
        block
        @click="confirmGetIndicatorStateInfo"
      >
        نمودار تغییرات
      </CButton> 
    </div>
    <div class="d-none d-md-block col-6 align-self-end mt-1"> 
      <CButton color="info" variant="outline" block @click="showHelp">
        <strong> راهنمای رنگ نقشه </strong>
      </CButton>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapActions } from "vuex";
import { apiUrlRoot } from "../../constants/config";

export default {
  props: {
    avaliabledateTimesList: {
      type: Object,
    },
    layerSelectedId: {
      type: Number,
    },
    farmSelected: {
      type: Object,
    },
  },
  components: { vSelect },
  data() {
    return {
      layerId: this.layerSelectedId,
      daySelected: "",
      month: null,
      selectedDate: null,
      farmSelectedRow: this.farmSelected,
      indicatorList: [],
      selectedMonth: "",
      monthAvaliableList: [],
      dayAvaliableList: [],
      formLoading: false,
      dayResultError: "برای نمایش روزها؛ یک سال و ماه را انتخاب کنید",
      monthAvaliableDefault: "",
    };
  },
  mounted() {
    this.monthAvaliableList = [];
    this.avaliabledateTimesList.data.forEach((element) => {
      this.monthAvaliableList.push({
        value: element.dateTimeValue,
        label: element.dateTimeLabel,
      });
    });
    if (this.monthAvaliableList.length == 0) {
      this.monthAvaliableDefault = "زمین تاریخ ندارد...";
      this.monthAvaliableList.push({
        value: null,
        label: null,
      });
    } else {
      this.monthAvaliableDefault = "انتخاب سال و ماه...";
    }
    this.getAllIndicator();
  },
  watch: {
    selectedMonth: function () {
      this.getAvaliableDays(this.selectedMonth.value);
    },
  },
  computed: {},
  methods: {
    ...mapActions("indicatorClient", [
      "GetAllWithProduct",
      "GetAvailableDay",
      "GetIndicatorFile",
      "GetIndicatorState",
    ]),
    getSrc(src, folder) {
      return `${apiUrlRoot}/${folder}/${src}`;
    },
    async getAllIndicator() {
      let data = {
        FarmId: this.farmSelectedRow.id,
      };
      let result = await this.GetAllWithProduct(data);
      if (result.length > 0) {
        this.indicatorList = result;
      } else {
        this.$notify({
          group: "dashboard",
          title: "خطا",
          text: `هیچ شاخصی برای سیستم تعریف نشده است <br /><br />`,
          type: "error",
        });
      }
    },
    async getAvaliableDays(date) {
      if (date) {
        let model = {
          date: date,
          farmId: this.farmSelectedRow.id,
        };
        this.formLoading = true;
        this.dayAvaliableList.splice(0);
        this.dayAvaliableList = [];
        let result = await this.GetAvailableDay(model);
        this.formLoading = false;
        if (result) {
          if (result.succeeded) {
            this.dayAvaliableList = result.data.day;
          } else {
            this.dayResultError = result.message;
          }
        } else {
          this.$notify({
            group: "dashboard",
            title: "پیام سیستم",
            text: `خطا در دریافت اطلاعات <br /><br />`,
            type: "error",
          });
        }
      } else {
        this.$notify({
          group: "dashboard",
          title: "خطا",
          text: `سال و ماه انتخاب شده معتبر نیست <br /><br />`,
          type: "info",
        });
      }
    },
    async getIndicatorFile() {
      if (!this.daySelected) {
        this.$notify({
          group: "dashboard",
          title: "پیام سیستم",
          text: `هیچ روزی انتخاب نشده است <br /><br />`,
          type: "error",
        });
      } else if (!this.layerId) {
        this.$notify({
          group: "dashboard",
          title: "پیام سیستم",
          text: `هیچ شاخصی انتخاب نشده است <br /><br />`,
          type: "error",
        });
      } else {
        let model = {
          IndicatorId: this.layerId,
          FarmId: this.farmSelectedRow.id,
          Year: this.selectedMonth.value.split("/")[0],
          Month: this.selectedMonth.value.split("/")[1],
          Day: this.daySelected,
        };
        this.formLoading = true;
        let result = await this.GetIndicatorFile(model);
        this.formLoading = false;
        if (result) {
          if (result.succeeded) {
            this.$notify({
              group: "dashboard",
              title: "پیام سیستم",
              text: `شاخص انتخابی به روز شد <br /><br />`,
              type: "success",
            });
            let layerUrl = this.getSrc(result.data.imageName, "SentinelFile");
            let layerSelectedRow = this.indicatorList.filter(
              (n) => n.id == this.layerId
            );
            this.$emit(
              "changeLayerImage",
              layerUrl,
              layerSelectedRow[0].helpImage
            );
          } else {
            this.dayResultError = result.message;
          }
        } else {
          this.$notify({
            group: "dashboard",
            title: "پیام سیستم",
            text: `خطا در دریافت اطلاعات <br /><br />`,
            type: "error",
          });
        }
      }
    },
    async setDaySelected(day) {
      this.daySelected = day;
      await this.getIndicatorFile();
    },
    async setIndicatorSelected(id) {
      this.layerId = id;
      await this.getIndicatorFile();
    },
    async getIndicatorStateInfo() {
      if (!this.daySelected) {
        this.$notify({
          group: "dashboard",
          title: "پیام سیستم",
          text: `هیچ روزی انتخاب نشده است <br /><br />`,
          type: "error",
        });
      } else if (!this.layerId) {
        this.$notify({
          group: "dashboard",
          title: "پیام سیستم",
          text: `هیچ شاخصی انتخاب نشده است <br /><br />`,
          type: "error",
        });
      } else {
        this.formLoading = true;
        let data = {
          Year: this.selectedMonth.value.split("/")[0],
          Month: this.selectedMonth.value.split("/")[1],
          Day: this.daySelected,
          FarmId: this.farmSelectedRow.id,
          IndicatorId: this.layerId,
        };
        let result = await this.GetIndicatorState(data);
        this.formLoading = false;
        if (result) {
          if (result.succeeded) {
            this.$emit(
              "showLayerState",
              result.data.dateList,
              result.data.dataList,
              result.data.title
            );
          } else {
            this.$notify({
              group: "dashboard",
              title: "خطا در نمودار",
              text: `${result.message} <br /><br />`,
              type: "error",
            });
            return;
          }
        } else {
          this.$notify({
            group: "dashboard",
            title: "پیام سیستم",
            text: `خطا در دریافت اطلاعات <br /><br />`,
            type: "error",
          });
        }
      }
    },
    confirmGetIndicatorStateInfo() {
      this.$confirm({
        message: `این گزینه محدودیت درخواست دارد. مطمئن هستید؟`,
        button: {
          no: "لغو",
          yes: "بله",
        },
        callback: (confirm) => {
          if (confirm) {
            this.getIndicatorStateInfo();
          }
        },
      });
    },
    showHelp() {
      this.$emit("showHelpColor");
    },
  },
};
</script>

<style scoped>
.indexInfo {
  width: auto !important;
}

.selectStyle {
  width: 100%;
  background-color: #fff;
  cursor: pointer !important;
  border-radius: 0;
  border: none;
}

.vs__dropdown-toggle {
  border: none;
  border-radius: 0;
}

.calendeBtn {
  border-radius: 0.25rem;
  border: 1px solid #ccc;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}

.calendeBtn.day {
  width: 2rem;
  margin: 2px;
}

.calendeBtn:hover {
  background: #ccc;
}

.selectedBtnStyle {
  background-color: rgb(250 235 215);
}

.selectedImgBtn {
  border: 3px solid #f1f1f1;
}

.dayAvaliable {
  background: #fff;
  max-height: 7rem;
  width: 100%;
  overflow-y: auto;
}

.dayAvaliable::-webkit-scrollbar {
  width: 8px;
}

.dayAvaliable::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 20px;
}

.dayAvaliable::-webkit-scrollbar-thumb {
  background: #a1a1a1;
  border-radius: 10px;
}

.dayAvaliable::-webkit-scrollbar-thumb:hover {
  background: rgb(83, 83, 83);
}
</style>
