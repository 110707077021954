var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container m-0 p-0"},[_c('div',{staticClass:"row d-block d-md-none",attrs:{"id":"mapSwitch","align":"center"}},[_c('div',{staticClass:"col-12"},[_c('button',{staticClass:"switchBtn",class:{
					on: _vm.isSatelite,
				},staticStyle:{"border-radius":"0 4px 4px 0"},on:{"click":function($event){return _vm.toggleMapStyle(
						'hamidkazemzadeh/ckq2sozke25hf17qh16mdszbh'
					)}}},[_vm._v(" ماهواره ")]),_c('button',{staticClass:"switchBtn",class:{
					on: !_vm.isSatelite,
				},staticStyle:{"border-radius":"4px 0 0 4px"},on:{"click":function($event){return _vm.toggleMapStyle(
						'hamidkazemzadeh/ckqgm1p580ctf17o2kalvw6wu'
					)}}},[_vm._v(" نقشه ")])])]),_c('div',{staticClass:"row d-none d-md-block mapSwitcMd",attrs:{"align":"center"}},[_c('button',{staticClass:"switchBtn",class:{
					on: _vm.isSatelite,
				},staticStyle:{"border-radius":"0 4px 4px 0"},on:{"click":function($event){return _vm.toggleMapStyle(
						'hamidkazemzadeh/ckq2sozke25hf17qh16mdszbh'
					)}}},[_vm._v(" ماهواره ")]),_c('button',{staticClass:"switchBtn",class:{
					on: !_vm.isSatelite,
				},staticStyle:{"border-radius":"4px 0 0 4px"},on:{"click":function($event){return _vm.toggleMapStyle(
						'hamidkazemzadeh/ckqgm1p580ctf17o2kalvw6wu'
					)}}},[_vm._v(" نقشه ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }