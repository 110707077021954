<template>
  <div>
    <div
      v-if="isPanelVisible"
      :class="{ rightPanel: rightPanel, leftPanel: !rightPanel }"
      class="d-block d-md-none"
    >
      <section
        class="py-2 header text-right"
        :class="{
            rightPanelHeader: rightPanel,
            leftPanelHeader: !rightPanel,
          }"
      >
          <span
            :class="{
              closeLeftPanel: !rightPanel,
              closeRightPanel: rightPanel,
            }"
          >
            <button class="closeBtn" @click="showLeftPanel">
              <CIcon name="cil-x" width="1.2rem" style="color: #a9a9a9"/>
            </button>
          </span>
        {{ title }}
      </section>
      <slot></slot>
    </div>
    <div
      :class="{
          rightPanelMd: rightPanel,
          leftPanelMd: !rightPanel,
        }"
      class="d-none d-md-block z-2">
      <slot></slot>
    </div>
    <div
      class="d-flex justify-content-stretch align-items-stretch d-block d-md-none"
      :class="{
        rightPanelArrow: rightPanel,
        leftPanelArrow: !rightPanel,
      }"
    ></div>
    <button
      class="arrowBtn leftPanelArrow d-block d-md-none"
      v-if="!rightPanel && !isPanelVisible"
      @click="showLeftPanel"
    >
      <CIcon name="cilChevronDoubleRight" style="color: cadetblue"/>
    </button>
    <button
      class="arrowBtn rightPanelArrow d-block d-md-none"
      v-if="rightPanel && !isPanelVisible"
      @click="showLeftPanel"
    >
      <CIcon name="cilChevronDoubleLeft" style="color: cadetblue"/>
    </button>
  </div>
</template>

<script>
export default {
  name: "BasePanelForm",
  props: {
    title: {
      type: String,
      default: "مشخصات زمین",
    },
    rightPanel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isPanelVisible: false,
      farmsSource: [],
      filteredList: [],
    };
  },

  methods: {
    showLeftPanel() {
      this.isPanelVisible = !this.isPanelVisible;
    },
  },

  mounted() {
  },
  // computed: {
  // 	fade() {
  // 		return this.rightPanel ? "rightPanelAnim" : "leftPanel";
  // 	},
  // },
};
</script>

<style>
.main-card {
  background: rgba(255, 255, 255, 1) !important;
}
</style>
<style scoped>
.rightPanel {
  background: rgba(255, 255, 255, 0.9);
  height: 17.5rem;
  position: absolute;
  top: 50%;
  margin-top: -7.5rem;
  right: 0;
  border-radius: 1rem 0 0 0.4rem;
}

.leftPanel {
  height: 17.5rem;
  width: 9rem;
  position: absolute;
  top: 50%;
  margin-top: -7.5rem;
  left: 0;
  border-radius: 0 1rem 0.4rem 0;
}

.rightPanelMd {
  position: absolute;
  left: 0;
  bottom: 10%;
  background-color: #fff;
  border-radius: 8px;
  width: 22rem;
  max-height: 28rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

.leftPanelMd {
  background: rgba(255, 255, 255, 0.85);
  position: absolute !important;
  z-index: 1;
  border-radius: var(--border-radius);
  overflow: hidden;
  right: 0;
  top: 0;
}

.header {
  font-size: 0.8rem;
  background: cadetblue;
  color: white;
}

.headerMd {
  font-size: 1rem;
  background: #005f00;
  color: white;
}

.rightPanelHeader {
  border-radius: 1rem 0 0 0;
  text-align: start;
}

.leftPanelArrow {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -1.5rem;
  margin-left: 0.2rem;
}

.rightPanelArrow {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -1.5rem;
  margin-right: 0.2rem;
}

.arrowBtn {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 1px solid #ccc;
}

.fade-enter-from {
  opacity: 0;
}

.fade-enter-active {
  transition: all 0.4s ease-out;
}

.fade-enter-to {
  opacity: 1;
}

.fade-leave-from {
  opacity: 1;
}

.fade-leave-active {
  transition: all 0.4s ease-out;
}

.fade-leave-to {
  opacity: 0;
}

@keyframes rightArrowAnimEnter {
  from {
    right: 0;
  }

  to {
    right: 9rem;
  }
}

@keyframes rightArrowAnimExit {
  0% {
    right: 9rem;
  }
  100% {
    right: 0;
  }
}

.rightArrowAnimationEnter {
  animation: rightArrowAnimEnter 0.4s ease-out forwards;
}

.rightArrowAnimationExit {
  animation: rightArrowAnimExit 0.4s ease-out forwards;
}

@keyframes leftArrowAnimEnter {
  from {
    left: 0;
  }

  to {
    left: 9rem;
  }
}

@keyframes leftArrowAnimExit {
  from {
    left: 9rem;
  }

  to {
    left: 0;
  }
}

.leftArrowAnimationEnter {
  animation: leftArrowAnimEnter 0.4s ease-out forwards;
}

.leftArrowAnimationExit {
  animation: leftArrowAnimExit 0.4s ease-out forwards;
}

.closeBtn {
  border-radius: 50%;
  border: 1px solid #a9a9a9;
  width: 2rem;
  height: 2rem;
}

.closeLeftPanel {
  position: relative;
  top: -1.1rem;
  left: 0.6rem;
}

.closeRightPanel {
  position: relative;
  top: -1.1rem;
  right: 7.5rem;
}
</style>
