<template>
	<CChartLine :datasets="defaultDatasets" :labels="datesList" />
</template>

<script>
	import { CChartLine } from "@coreui/vue-chartjs";

	export default {
		name: "CChartLineExample",
		components: { CChartLine },
		props: {
			title: {
				type: String,
			},
			dataList: {
				type: Array,
				// default: function () {
				// 	return [30, 39, 10, 50, 30, 70, 35, 95];
				// },
			},
			datesList: {
				type: Array,
				// default: function () {
				// 	return [
				// 		"فروردین",
				// 		"اردیبهشت",
				// 		"خرداد",
				// 		"تیر",
				// 		"مرداد",
				// 		"شهریور",
				// 	];
				// },
			},
		},

		computed: {
			defaultDatasets() {
				let indexTitle = this.title;
				let color="green";
				// switch (this.getTitle) {
				// 	case "NDVI":
				// 		indexTitle = "تغییرات پوشش گیاهی";
				// 		color = "green";
				// 		break;
				// 	case "RECI":
				// 		indexTitle = "تغییرات سلامت گیاه";
				// 		color = "orange";
				// 		break;
				// 	case "NDRE":
				// 		indexTitle = "تغییرات نیتروژن گیاه";
				// 		color = "aquamarine";
				// 		break;
				// 	case "NDMI":
				// 		indexTitle = "تغییرات تنش آبی گیاه";
				// 		color = "blue";
				// 		break;

				// 	default:
				// 		break;
				// }
				return [
					{
						fill: false,
						label: indexTitle,
						borderColor: color,
						data: this.dataList,
						borderWidth: 1,
						pointStyle: "star",
					},
				];
			},
			getTitle() {
				return this.title;
			},
		},
	};
</script>
<style scoped></style>
