<template>
  <div class="main-card">
    <div
      class="row ml-0 pr-4 pr-md-2 p-2 pt-md-3 d-block d-md-none animate__animated animate__slideInRight"
      style="max-height: 15rem; overflow-y: auto"
    >
      <h4 class="caption" style="border: none !important">طول جغرافیایی:</h4>
      <h6 class="value">
        {{
          getFarmInfo.coordination
            .substring(2, getFarmInfo.coordination.length - 1)
            .split(" ")[1]
            .substring(1, 8)
        }}
      </h6>
      <div class="caption">عرض جغرافیایی:</div>
      <div class="value">
        {{
        getFarmInfo.coordination
            .substring(1, getFarmInfo.coordination.length - 1)
            .split(" ")[2]
            .substring(0, 7)
        }}
      </div>

      <div class="caption">عنوان زمین:</div>
      <div class="value">
        {{ getFarmInfo.title }}
      </div>

      <div class="caption">نوع محصول:</div>
      <div class="value">
        {{ getFarmInfo.productType }}
      </div>

      <div class="caption">نام محصول:</div>
      <div class="value">
        {{ getFarmInfo.productName }}
      </div>

      <div class="caption">نوع کشت:</div>
      <div class="value">
        {{ cultiavtionType[+getFarmInfo.cultivationsType - 1] }}
      </div>

      <div class="caption">مساحت (هکتار):</div>
      <div class="value">
        {{ getFarmArea }}
      </div>
      <div class="caption">تاریخ کاشت:</div>
      <div class="value">
        {{ getFarmInfo.cultivationsDate }}
      </div>
    </div>
    <div
      class="row d-none d-md-block"
      style="overflow-y: auto"
    >
      <h5 class="px-3">
        {{getFarmInfo.title}}
      </h5>
      <hr class="my-2"/>
      <KeyValue
        :json='{
          // "طول جغرافیایی": getFarmInfo.coordination
          //         .substring(2, getFarmInfo.coordination.length - 1)
          //         .split(" ")[1]
          //         .substring(1, 8),
          // "عرض جغرافیایی": getFarmInfo.coordination
          //   .substring(1, getFarmInfo.coordination.length - 1)
          //   .split(" ")[2]
          //   .substring(0, 7),
          "نام محصول": getFarmInfo.productName,
          "نوع کشت": cultiavtionType[+getFarmInfo.cultivationsType - 1],
          "مساحت (هکتار)": getFarmArea,
          "تاریخ کاشت": toFarsiNumber(getFarmInfo.cultivationsDate),
        }'
      />
    </div>
  </div>
</template>

<script>

import KeyValue from "../keyValue.vue";
import {toFarsiNumber} from "../../utils";

export default {
  methods: {toFarsiNumber},
  components: {KeyValue},
  props: {
    farmInfo: {
      type: Object,
    },
    area: {
      type: String,
    },
  },
  data() {
    return {
      cultiavtionType: ["دیم", "آبی", "دیم -آبیاری تکمیلی"],
    };
  },
  computed: {
    getFarmInfo() {
      return this.farmInfo;
    },
    getFarmArea() {
      return this.area;
    },
    getLng() {
      return this.getFarmInfo.coordination
        .substring(7, this.getFarmInfo.coordination.length - 1)
        .split(" ")[0]
        .substring(0, 8);
    },
  },
};
</script>

<style scoped>
.caption {
  font-size: 0.9rem;
  width: 94%;
  clear: both;
  float: right;
  padding-right: 10px;
  margin-top: 3px;
  border-top: 1px dotted #bbb;
  padding-top: 4px;
}
.value {
  width: 100%;
  clear: both;
  float: right;
  padding-right: 10px;
}
</style>
