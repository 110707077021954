var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isPanelVisible)?_c('div',{staticClass:"d-block d-md-none",class:{ rightPanel: _vm.rightPanel, leftPanel: !_vm.rightPanel }},[_c('section',{staticClass:"py-2 header text-right",class:{
          rightPanelHeader: _vm.rightPanel,
          leftPanelHeader: !_vm.rightPanel,
        }},[_c('span',{class:{
            closeLeftPanel: !_vm.rightPanel,
            closeRightPanel: _vm.rightPanel,
          }},[_c('button',{staticClass:"closeBtn",on:{"click":_vm.showLeftPanel}},[_c('CIcon',{staticStyle:{"color":"#a9a9a9"},attrs:{"name":"cil-x","width":"1.2rem"}})],1)]),_vm._v(" "+_vm._s(_vm.title)+" ")]),_vm._t("default")],2):_vm._e(),_c('div',{staticClass:"d-none d-md-block z-2",class:{
        rightPanelMd: _vm.rightPanel,
        leftPanelMd: !_vm.rightPanel,
      }},[_vm._t("default")],2),_c('div',{staticClass:"d-flex justify-content-stretch align-items-stretch d-block d-md-none",class:{
      rightPanelArrow: _vm.rightPanel,
      leftPanelArrow: !_vm.rightPanel,
    }}),(!_vm.rightPanel && !_vm.isPanelVisible)?_c('button',{staticClass:"arrowBtn leftPanelArrow d-block d-md-none",on:{"click":_vm.showLeftPanel}},[_c('CIcon',{staticStyle:{"color":"cadetblue"},attrs:{"name":"cilChevronDoubleRight"}})],1):_vm._e(),(_vm.rightPanel && !_vm.isPanelVisible)?_c('button',{staticClass:"arrowBtn rightPanelArrow d-block d-md-none",on:{"click":_vm.showLeftPanel}},[_c('CIcon',{staticStyle:{"color":"cadetblue"},attrs:{"name":"cilChevronDoubleLeft"}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }